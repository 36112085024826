export type CalcModuleType = {
  name: string;
  base: number;
  baseCh: number;
  sample: number;
  sampleCh?: number;
};

export const EmptyCalcModule = Object.freeze<CalcModuleType>({
  name: "",
  base: 0,
  baseCh: 0,
  sample: 0,
  sampleCh: 0,
});
