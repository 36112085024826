import "./App.css";
import CalcArea from "./components/CalcArea/CalcArea";

function App() {
  return (
    <div className="App">
      <CalcArea />
    </div>
  );
}

export default App;
