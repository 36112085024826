import { useEffect, useState } from "react";
import { CalcModuleType } from "../../types";
import * as S from "./CalcModule.atoms";

type Props = {
  defaults?: CalcModuleType;
  onValueChange: (e: CalcModuleType) => void;
};

const CalcModule = ({ defaults, onValueChange }: Props) => {
  const [name, setName] = useState(defaults?.name ?? "");
  const [base, setBase] = useState(defaults?.base ?? 0);
  const [baseCh, setBaseCh] = useState(defaults?.baseCh ?? 0);
  const [sample, setSample] = useState(defaults?.sample ?? 0);
  const [sampleCh, setSampleCh] = useState(0);

  useEffect(() => {
    const chPerUnit = (100 / base) * baseCh;
    const result = chPerUnit * (sample / 100);
    const normalizedResult = isNaN(result) ? 0 : Math.round(result * 10) / 10;
    setSampleCh(normalizedResult);
  }, [base, baseCh, sample]);

  useEffect(() => {
    onValueChange({ name, base: base, baseCh: baseCh, sample, sampleCh });
  }, [name, base, baseCh, sample, sampleCh]);

  return (
    <S.Container>
      <div>
        <S.Name
          placeholder={"Név"}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </div>
      <div>
        Ha{" "}
        <S.Input
          type="number"
          value={base}
          onChange={(e) => setBase(e.currentTarget.valueAsNumber)}
          min="0"
        />{" "}
        g tartalmaz{" "}
        <S.Input
          type="number"
          value={baseCh}
          onChange={(e) => setBaseCh(e.currentTarget.valueAsNumber)}
          min="0"
          hasError={baseCh > base}
        />{" "}
        g szénhidrátot, akkor{" "}
        <S.Input
          type="number"
          value={sample}
          onChange={(e) => setSample(e.currentTarget.valueAsNumber)}
          min="0"
        />{" "}
        g <S.Result>{sampleCh}</S.Result> g szénhidrátot tartalmaz.
      </div>
    </S.Container>
  );
};

export default CalcModule;
