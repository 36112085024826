import { faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { CalcModuleType, EmptyCalcModule } from "../../types";
import CalcModule from "../CalcModule/CalcModule";
import * as S from "./CalcArea.atoms";

const CalcArea = () => {
  const load = () => {
    const newRows: CalcModuleType[] = [EmptyCalcModule];
    const recipes = JSON.parse(
      localStorage.getItem("recipes") ?? "[]"
    ) as string[];
    if (!recipes.length) return newRows;
    if (recipes[0] !== "_v1") return newRows;
    const recipe: { rows: CalcModuleType[] } = JSON.parse(
      localStorage.getItem("_v1") ?? "{}"
    );
    if (!recipe.rows) return newRows;
    newRows.pop();
    recipe.rows.forEach((row) => newRows.push(row));
    return newRows;
  };

  const save = () => {
    localStorage.setItem("recipes", JSON.stringify(["_v1"]));
    localStorage.setItem("_v1", JSON.stringify({ rows }));
    setNeedsToSave(false);
  };

  const [rows, setRows] = useState<CalcModuleType[]>(load());
  const [needsToSave, setNeedsToSave] = useState(false);

  const addRow = () => {
    const newRows = [...rows];
    newRows.push(EmptyCalcModule);
    setRows(newRows);
  };

  const deleteRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const updateRow = (index: number, row: CalcModuleType) => {
    const newRows = [...rows];
    newRows[index] = row;
    setRows(newRows);
  };

  const chSum = useMemo(() => {
    setNeedsToSave(true);
    let sum = 0;
    for (let i = 0; i < rows.length; i++) {
      sum += rows[i].sampleCh ?? 0;
    }
    return Math.round(sum * 10) / 10;
  }, [rows]);

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  return (
    <S.Container>
      {rows.map((row, index) => (
        <S.Row key={index}>
          <CalcModule
            defaults={row}
            onValueChange={(e) => updateRow(index, e)}
          />
          <S.Buttons>
            <button disabled={rows.length < 2} onClick={() => deleteRow(index)}>
              <FontAwesomeIcon icon={faTrash} /> Törlés
            </button>
          </S.Buttons>
        </S.Row>
      ))}
      <S.NewRow>
        <button onClick={addRow}>
          <FontAwesomeIcon icon={faPlus} /> Új sor
        </button>
        <button disabled={!needsToSave} onClick={save}>
          <FontAwesomeIcon icon={faSave} /> Mentés
        </button>
      </S.NewRow>
      <S.SumTotal>Összesen: {chSum}g</S.SumTotal>
    </S.Container>
  );
};

export default CalcArea;
