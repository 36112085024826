import styled from "styled-components";

export const Container = styled.div``;

export const Result = styled.span`
  font-weight: bold;
`;

export const Input = styled.input<{ hasError?: boolean }>`
  width: 4rem;
  box-shadow: ${({ hasError }) => (hasError ? "0px 0px 2px red" : "")};
`;

export const Name = styled.input`
  width: 20rem;
  border: none;
  outline: none;
  border-bottom: 1px solid darkgray;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;

  :active,
  :focus-within {
    border-bottom-color: lightskyblue;
  }

  ::placeholder {
    font-style: italic;
  }
`;
