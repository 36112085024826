import styled from "styled-components";

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
`;

export const Buttons = styled.div`
  margin-left: 1rem;

  > button {
    margin-right: 0.5rem;
  }
`;

export const SumTotal = styled.div`
  font-size: 1.5rem;
  border-top: 1px solid darkgray;
  margin-top: 1rem;
  padding-top: 0.5rem;
`;

export const NewRow = styled.div`
  margin-top: 1rem;

  > button {
    margin-right: 0.5rem;
  }
`;
